<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="pub_title_box flex">
      <pubSearch @search="search"/>
      <pubStatus @status_tab="status_tab"/>
    </div>
    <table>
      <thead>
      <tr>
        <td>媒介名称</td>
        <td>价格</td>
        <td>门户类型</td>
        <td>收录效果</td>
        <td>覆盖区域</td>
        <td>周末是否可发</td>
        <!-- <td>出稿率</td> -->
        <td>是否可带链接</td>
        <td>可发联系方式</td>
        <td>电脑权重</td>
        <td>移动权重</td>
<!--        <td>下单备注</td>-->
        <td>状态</td>
        <td>退回原因</td>
        <td>操作</td>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in tableData" :key="index">
        <td class="over_text">
          <!--          <td_remark :text="item.title"/>-->
          {{ item.title }}
        </td>
        <td>￥{{ item.medium_price }}</td>

        <td>{{ item.portal_title }}</td>
        <td>{{
            item.inlet_category == 0 ? '不限' : item.inlet_category == 1 ? '包网页收录' :
                item.inlet_category == 2 ?
                    '网页收录' : item.inlet_category == 3 ? '包百度新闻源收录' : '百度新闻源收录'
          }}
        </td>
        <td>{{ item.area_title }}</td>
        <td :class="item.is_pub_weekend ? 'td_true' : 'td_false'">{{ item.is_pub_weekend ? '是' : '否' }}</td>
        <td :class="item.is_pub_link ? 'td_true' : 'td_false'">{{ item.is_pub_link ? '是' : '否' }}</td>
        <td :class="item.contact ? 'td_true' : 'td_false'">{{ item.contact ? '是' : '否' }}</td>
        <td>
          <p class="weight_back">{{ item.weight }}</p>
        </td>
        <td>
          <p class="weight_back">{{ item.m_weight }}</p>
        </td>
<!--        <td>-->
<!--          <td_remark :text="item.remark"/>-->
<!--        </td>-->
        <td
            :style="{color: item.examine_status == 0 ? '#000' : item.examine_status == 1 && item.status == 0 ? '#00B052' : item.examine_status == 1 && item.status == 0 ? '#00B052' : '#FF0000' }">
          {{ item.examine_status == 0 ? '待审核' : '' }}
          {{ item.examine_status == 1 && item.status == 0 ? '已审核' : '' }}
          {{ item.examine_status == 2 ? '被退回' : '' }}
          {{ item.status == 1 ? '暂停中' : '' }}
        </td>
        <td>{{ item.reason != '' && item.examine_status != 1 ? item.reason : '-' }}
        </td>
        <td>
                        <span style="cursor: pointer; color: #108CDD;margin-right: 1rem;"
                              @click.stop="upDateFn(item)">编辑</span>
          <span style="cursor: pointer; color: #00B052;" v-show="item.status == 1"
                @click="UpClick(item)">申请上架</span>

          <span style="cursor: pointer;color: #FF8400;"
                v-show="item.status == 0 && item.examine_status == 1" @click="reason(item)">暂停</span>
        </td>
      </tr>
      </tbody>
    </table>
    <!-- 分页器 -->
    <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>
  </div>
</template>

<script>
import pubStatus from '@/components/pub_media_status'
import pubSearch from '@/components/pubSearch.vue'

export default {
  name: '',
  components: {
    pubStatus,
    pubSearch
  },
  mounted() {
    this.get_list()
  },
  data() {
    return {
      title: '',
      fullscreenLoading: false,
      tableData: [],
      total_page: 0, //分页总页数
      count: 0, //总条数
      page: 1,
    }
  },
  methods: {
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    get_list() {
      this.fullscreenLoading = true
      let data = {}
      data.category_id = 1
      data.page = this.page
      data.limit = 20
      if (this.examine_status !== '') {
        data.examine_status = this.examine_status
      }
      if (this.title != '') {
        data.title = this.title
      }
      this.curlGet('/api/users/medium/list', data).then(res => {
        if (res.data.code) {
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
          this.tableData = res.data.data.list
        }
        this.fullscreenLoading = false

      })
    },
    search(title) {
      this.title = title
      this.get_list()
    },
    status_tab(id) {
      this.examine_status = id
      this.get_list()
    },
    // 暂停
    reason(item) {
      this.$confirm('是否暂停该媒体?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/api/users/medium/edit_status', {
          id: item.id,
          status: 1,
        }).then(res => {
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: '暂停成功'
            });
            this.get_list()
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消暂停'
        });
      });

    },
    // 申请上架
    UpClick(item) {
      this.$confirm('是否申请上架?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/api/users/medium/apply', {
          id: item.id
        }).then(res => {
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: '申请成功'
            });
            this.get_list()
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消暂停'
        });
      });

    },
    // 编辑
    upDateFn(item) {
      this.$store.state.AddRs = item.category_id
      this.$store.state.active = item.category_id
      this.$router.push({
        path: '/user/add_resource/News',
        query: {
          // id: this.$store.state.AddRsId
          id: item.id
        }
      })
    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/pubTable';
</style>